.news-carousel-container {
  width: 100%;
  overflow: hidden;
  height: 70%;
}

.news-item {
  position: relative;
}

.news-content-container {
  background-color: #0a4275;
  width: 100%;
}

.news-image-container {
  width: 100%;
  height: 450px; /* Set a fixed height for all images */
  overflow: hidden;
  position: relative;
}

.news-image {
  width: 70%;
  height: 100%;
  object-fit: fill;
  margin: auto; /* Center the image horizontally */
  display: block; /* Ensures the margin auto works */
}

.news-content {
  padding: 20px;
  color: white;
}

.news-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.news-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.news-date {
  font-size: 14px;
  color: #ccc;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  /* background-color: rgba(0, 0, 0, 0.5); */
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  color: white;
  font-size: 18px;
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}
