@tailwind base;
@tailwind components;
@tailwind utilities;


.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensures it stays above other content */
  }